<template>
  <div style="inset: 0; position: absolute; overflow: auto">
    <div style="height: 100%">
      <v-row style="height: 100%" class="ma-0 pb-0">
        <v-col
          style="
            height: 100%;
            overflow: auto;
            background-color: rgb(240, 240, 240);
          "
          cols="12"
          sm="3"
          class="pa-0"
        >
          <router-view name="chatsList" />
        </v-col>
        <v-col
          v-if="
            $vuetify.breakpoint.smAndDown ? $route.name === 'chat dialog' : true
          "
          style="height: 100%; overflow: auto; border-top: 1px solid #dee2e6"
          cols="9"
        >
          <div v-if="currentUser.role === 'admin'">
            <router-view name="chatDialogWholesale" />
          </div>
          <div v-else>
            <router-view name="chatDialog" />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'Chat',
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>
<style scoped></style>
